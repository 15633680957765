<template>
<v-container class="app-container">
    <v-row>
        <v-col cols="12">
            <v-card>
               <v-list-item>
                    <v-list-item-avatar>
                        <v-img :src="$store.state.auth.profile.picture"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{$store.state.auth.profile.name}} {{$store.state.auth.profile.family_name}}</v-list-item-title>
                        <v-list-item-subtitle>{{$store.state.auth.profile.employee && $store.state.auth.profile.employee.aid ? $store.state.auth.profile.employee.aid : 'Nicht als  Mitarbeiter:in angemeldet'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card>
                <v-card-title>Fehlende Berechtigungen</v-card-title>
                <v-card-text>
                    Sie besitzen nicht die erforderlichen Berechtigungen, um auf den NEW Wunschbaum zugreifen zu können.<br>
                    Bitte beachten Sie, dass Sie nur mit einem NEW Mitarbeiterkonto auf den Wunschbaum der NEW zugreifen können.
                    <br><br>
                    <v-btn @click="signOut" color="primary" text>Konto wechseln</v-btn>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
import { auth } from '../firebase'
export default {
    methods: {
        async signOut() {
            if(auth.currentUser != null) {
                await auth.signOut()
            }
            loginNew.logout()
        }
    }
}
</script>